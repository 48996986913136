const GoldChain = {
    hex: '0x777771',
    name: 'GoldChain',
    rpcUrl: 'http://localhost:8080/https://rpc.goldchain.tech/',
    ticker: "BAIT"
};

const MumbaiTestnet = {
    hex: '0x13881',
    name: 'Mumbai Testnet',
    rpcUrl: 'https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78',
    ticker: "MATIC"
};

export const CHAINS_CONFIG = {
    "0x777771": GoldChain,
    "0x13881": MumbaiTestnet,
};